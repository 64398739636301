/* colors */
.blue{
    color: #2794D8;
} 
.bg-blue{
    background-color: #2794D8;
} 
.blue-2{
    color: #4889B2;
} 
.bg-blue-2{
    background-color: #4889B2;
} 
.blue-dark-1{
    color: #38596E;
} 
.bg-blue-dark-1{
    background-color: #38596E;
} 
.blue-dark-2{
    color: #213668;
} 
.bg-blue-dark-2{
    background-color: #213668;
} 
.blue-light-1{
    color: #8ac5eb;
} 
.bg-blue-light-1{
    background-color: #8ac5eb;
} 
.blue-light-2{
    color: #b9dcf3;
} 
.bg-blue-light-2{
    background-color: #b9dcf3;
} 
.orange{
    color: #E7590F;
} 
.bg-orange{
    background-color: #E7590F;
} 
.red{
    color: #BF2222;
} 
.bg-red{
    background-color: #BF2222;
} 
.green{
    color: #4CAF50;
} 
.bg-green{
    background-color: #4CAF50;
} 
.grey{
    color: #BCBCBC;
} 
.bg-grey{
    background-color: #BCBCBC;
} 
.grey-dark{
    color: #828282;
} 
.bg-grey-dark{
    background-color: #828282;
} 
.grey-light{
    color: #ECECEC;
} 
.bg-grey-light{
    background-color: #ECECEC;
} 
.white{
    color: #FFFFFF;
}
.bg-white{
    background-color: #FFFFFF;
}
.dark{
    color: #292929;
}
.bg-dark{
    background-color: #292929;
}

/* icons filter */
.white-filter
{
    filter: invert(86%) sepia(95%) saturate(2%) hue-rotate(153deg) brightness(109%) contrast(100%);
}

.grey-filter
{
    filter: invert(51%) sepia(3%) saturate(18%) hue-rotate(348deg) brightness(100%) contrast(93%);
}

.dark-filter
{
    filter: invert(14%) sepia(17%) saturate(13%) hue-rotate(3deg) brightness(92%) contrast(93%);
}
/* text */
.bold{
    font-weight: bold;
}
.bolder{
    font-weight:bolder;
}

/* display */
.none{
    display: none;
}
.block{
    display: block;
}
.relative{
    position: relative;
}
.absolute{
    position: absolute;
}
.left{
    text-align: left;
}
.right{
    text-align: right;
}
.border{
    border: 1px #828282 solid;
}
.border-white{
    border: 1px #fff solid;
}
.border-dark{
    border: 1px #292929 solid;
}
.no-border
{
    border: 0;
}
.w-200{
    width: 200px;
}
.z-10{
    z-index: 10;
}
.underline
{
    text-decoration: underline;
}
.rotate-left
{
    transform: rotate(90deg);
}
    
.rotate-right
{
    transform: rotate(-90deg);
}

/* event */
.cursor:hover{
    cursor: pointer;
}

.not-allowed:hover{
    cursor:not-allowed;
}
/* Boostrap */
.accordion-item
{
    border: 0;
}

.accordion
{
    --bs-accordion-border-radius: 0;
    --bs-accordion-border-width: 0;
}

.accordion-button:after 
{
    background-image: url("/assets/icons/chevron-down.png");
    background-position: center;
    background-size: 15px;
    /* Filtre blanc */
    filter: invert(86%) sepia(95%) saturate(2%) hue-rotate(153deg) brightness(109%) contrast(100%);
}

.accordion-button:focus 
{
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
}

.accordion-button:not(.collapsed)
{
    background-color:  #2794D8;
    color: white;
}

/* Fin Boostrap */
/* DIALOG */
.dialog
{
    max-width: 750px;
    max-height: 800px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dialog-title
{
    padding: 3.5rem 1.5rem;
    font-size: 1.6rem;
}

.dialog-content-group
{
    position: relative;
}

.dialog-content
{
    padding: 3.5rem 2.5rem;
}

.timer-text
{
    font-size: 12px
}

.change-password
{
    border: 0;
    background-color: #2794D8;
    color: white;
    font-weight: bold;
    padding: 0.25rem 1rem;
}

/* FIN DIALOG */
 /* ARIAL */
 @font-face {
    font-family: "Arial-Italic";
    src: url("./../assets/fonts/Arial/ARIALI.TTF");
}
@font-face {
    font-family: "Arial-Bold-Italic";
    src: url("./../assets/fonts/Arial/ARIALBI.TTF");
}


 /* INTER */
@font-face {
    font-family: "Inter-Bold";
    src: url("./../assets/fonts/Inter/Inter-Bold.ttf");
}
@font-face {
    font-family: "Inter-Light";
    src: url("./../assets/fonts/Inter/Inter-Light.ttf");
}
@font-face {
    font-family: "Inter-Regular";
    src: url("./../assets/fonts/Inter/Inter-Regular.ttf");
}

/* font */
h1{
    font-size: 4em;
    line-height: 0.6;
    font-weight: 400;
    margin: 12px 0 80px 0;
}
.normal{
    font-size: 1em;
    line-height: 1.2;
    font-weight: normal;
}
.medium{
    font-size: 1.3125em;
}
.small{
    font-size: 0.8em;
}
.smaller{
    font-size: 0.5em;
}
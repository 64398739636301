/* Style tableau */
tr:not(.hors-delai)
{
    color: #292929;
}
tr:is(.hors-delai)
{
    color: #E7590F;
}
tr:is(.blue)
{
    color:  #2794D8;
}

.table
{
    padding: 3rem 0 0 2.5rem; 
}

.table-overflow
{
    max-height: 700px;
    overflow-y: scroll;
}

.table-icons
{
    height: 20px;
}

.header-table
{
    font-family: "Inter-Regular";
}

.header-table th
{
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 1rem;
    text-align: start;
}

.header-table-no-border th
{
    border: 0
}

.content-table
{
    font-family: "Inter-Light";
}

.content-table td
{
    padding-left: 0;
    padding-right: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.content-table:is(.red)
{
    color: #BF2222;
}

.content-table:is(.orange)
{
  color: #e65a0f;
}

.content-table:is(.bold), 
.content-table-details:is(.bold), 
.header-table th, 
.header-table-bold th,
tr:is(.blue)
{
  font-weight: 500;
}

.content-table-details:is(.orange)
{
  color: #E7590F;
}

.table > :not(caption) > * > *
{
    padding: 0;
    border-bottom-width: 0;
}

.mat-mdc-header-cell
{
    font-weight: bold;
}

.mat-mdc-header-row
{
    border-bottom: 2px solid #828282;
}

.mat-column-date
{
    width: 150px;
}

.mat-column-indication
{
    width: 50%;
}

.mat-sort-header-container 
{
    justify-content: flex-start !important;
}

.mat-sort-header-container:is(.centedred)
{
    justify-content: center !important;
}

.mat-column-user_agent
{
    width: 300px;
    padding-right: 0.5rem;
}